import app from './app';
import config from '../config';
import navigation from './navigation';
import { getParams, getHash } from './utils';
import { listenVKWebAppUpdateConfig } from './vk';
import { getData } from './back';

export function go(callback) {

	listenVKWebAppUpdateConfig((data) => {
		let scheme = data.scheme ? data.scheme : 'client_light';
		app.setState({
			scheme: scheme
		})
	})

	window.onpopstate = (e) => {
		navigation.nativeGoBack()
		return;
	}

	const params = getParams()
	const hash = getHash()

	config.set({
		params: params,
		hash: hash,
		app_id: +params.vk_app_id
	}, () => {
		startApp({})
	})
}

export function startApp(response) {
	getData((data) => {
		const { categories, topics } = data
		app.setState({
			isLoad: true,
			header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true, //показываем или скриваем хедер при кастомном хедере на десктопе 
			isStartInGroup: isStartInGroup(),
			isAdminInGroup: isAdminInGroup(),
			goStartPanel: isStartPanel(),
			categories:categories,
			topics:topics,
			...response
		})
	})
}

function isAdminInGroup() {
	return (config.params.vk_viewer_group_role === 'admin') ? true : false
}

function isStartPanel() {
	//проверить localstorage
	if (isStartInGroup() || !config.startPanel) {
		return false
	} else {
		let welcome = localStorage.getItem('welcome')
		if (!welcome && config.startPanel) {
			return true
		} else {
			return false
		}
	}
}

function isStartInGroup() {
	return (config.params.vk_group_id) ? true : false
}